import { CalendarEvent } from 'src/components/Schedule/types';

export const CALENDAR_EVENTS_MOCK: CalendarEvent[] = [
    {
        start: new Date(new Date().setHours(10, 0, 0, 0)),
        end: new Date(new Date().setHours(11, 0, 0, 0)),
        title: 'Інформатика',
        listOfClasses: ['class_7', 'class_1'],
        id: crypto.randomUUID()
    },
    {
        start: new Date(new Date().setHours(10, 30, 0, 0)),
        end: new Date(new Date().setHours(11, 30, 0, 0)),
        title: 'Фізкультура',
        listOfClasses: ['class_7'],
        id: crypto.randomUUID()
    },
    {
        start: new Date(new Date().setHours(13, 0, 0, 0)),
        end: new Date(new Date().setHours(14, 0, 0, 0)),
        title: 'Іноземна мова (англійська)',
        listOfClasses: ['class_7'],
        id: crypto.randomUUID()
    },
    {
        title: 'Математика',
        listOfClasses: ['class_7'],
        id: crypto.randomUUID()
    },
    {
        title: 'Малювання',
        listOfClasses: ['class_7'],
        id: crypto.randomUUID()
    },
    {
        title: 'Основи Захисту Вітчизни',
        listOfClasses: ['class_7'],
        id: crypto.randomUUID()
    },
    {
        title: 'Образотворче мистецтво',
        listOfClasses: ['class_7'],
        id: crypto.randomUUID()
    }
];
