import { FC, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Card, Tabs, Tab, Box } from '@mui/material';
import { useQuery } from 'react-query';

import { CalendarEvent } from '../types';
import { EducationLevelGroup, listOfClass, Resource } from 'src/constants';
import { ScheduleToolbar } from './ScheduleToolbar';
import { ScheduleCalendar } from './ScheduleCalendar';
import { LessonsDuration } from 'src/constants/centers';
import { useFormContext, useWatch } from 'react-hook-form';

export const TabbedScheduleCalendar: FC<any> = ({ handleViewMode }) => {
    const dataProvider = useDataProvider();
    const { setValue } = useFormContext();
    const [activeTab, setActiveTab] = useState<EducationLevelGroup>(EducationLevelGroup.PRESCHOOL);
    // todo: query grade data from BE
    const gradeData = {
        lessonDuration: 1000 * 60 * LessonsDuration.MINUTES60
    };

    const { data = [] } = useQuery<CalendarEvent[]>(activeTab, () =>
        dataProvider.getGroupList(Resource.SCHEDULE, {
            meta: { educationLevelGroup: activeTab }
        })
    );

    const handleChangeTab = (_: any, value: any) => {
        setActiveTab(value);
        setValue(
            'listOfClasses',
            listOfClass[value as EducationLevelGroup].map((x: any) => x.id)
        );
    };

    const viewMode = useWatch({
        name: 'viewMode',
        defaultValue: null
    });

    useEffect(() => {
        handleViewMode(viewMode);
    }, [viewMode]);

    return (
        <Box>
            <ScheduleToolbar />
            <Card>
                <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab value={EducationLevelGroup.PRESCHOOL} label="Дошкільна освіта" />
                    <Tab value={EducationLevelGroup.JUNIOR_SCHOOL} label="1-4 класи" />
                    <Tab value={EducationLevelGroup.HIGH_SCHOOL} label="5-11 класи" />
                </Tabs>
                <ScheduleCalendar
                    events={data}
                    config={{ lessonDurationMs: gradeData.lessonDuration }}
                    activeTab={activeTab}
                />
            </Card>
        </Box>
    );
};
