import { UserRole } from '../auth/types';
import { EducationLevelGroup } from './school';

export const FILTER_EMPTY_VALUE = 'ALL';

export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    OTHER = 'OTHER'
}

export const UserRoleLabel: { [key in UserRole]: string } = {
    [UserRole.SYSTEM_ADMIN]: 'Системний адміністратор',
    [UserRole.SECRETARIAT]: 'Секретаріат',
    [UserRole.ADMIN]: 'Завідувач',
    [UserRole.PSYCHOLOGIST]: 'Психолог',
    [UserRole.PRESCHOOL_TEACHER]: 'Вихователь',
    [UserRole.ASSISTANT_PRESCHOOL_TEACHER]: 'Асистент вихователя',
    [UserRole.JUNIORS_TEACHER]: 'Вчитель молодших класів',
    [UserRole.ASSISTANT_JUNIORS_TEACHER]: 'Асистент вчителя',
    [UserRole.TEACHER]: 'Вчитель',
    [UserRole.PARENT]: 'Представник дитини',
    [UserRole.SPEECH_THERAPIST]: 'Логопед',
    [UserRole.INTELLECTUAL_DISABILITY_TEACHER]: 'Олігофренопедагог',
    [UserRole.VISION_TEACHER]: 'Тифлопедагог',
    [UserRole.DEAF_TEACHER]: 'Сурдопедагог',
    [UserRole.METHODOLOGIST]: 'Методист'
};

export const BooleanStringMapping: { [key: string]: boolean } = {
    true: true,
    false: false
};

export const YesNoChoices = [
    { id: 'yes', value: true, name: 'Так' },
    { id: 'no', value: false, name: 'Ні' }
];

export enum STATUS {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED'
}

export const StatusLabel = {
    [STATUS.ALL]: 'Усі',
    [STATUS.ACTIVE]: 'Активний',
    [STATUS.INACTIVE]: 'Неактивний',
    [STATUS.SUSPENDED]: 'Відсторонений'
};

export enum EmployeeTab {
    GENERAL = 'GENERAL',
    SCHEDULE = 'SCHEDULE',
    REPORTS = 'REPORTS'
}

export enum StaffRate {
    RATE_1 = 'RATE_1',
    RATE_0_75 = 'RATE_0_75',
    RATE_0_5 = 'RATE_0_5'
}

export const StaffRateLabel: { [key in StaffRate]: string } = {
    RATE_1: '1,0 ставки',
    RATE_0_75: '0,75 ставки',
    RATE_0_5: '0,5 ставки'
};

export const StaffRateHours = {
    [StaffRate.RATE_1]: 40,
    [StaffRate.RATE_0_75]: 30,
    [StaffRate.RATE_0_5]: 20
};

export enum PassportType {
    ID_CARD = 'ID_CARD',
    OLD_PASSPORT = 'OLD_PASSPORT'
}

export const PassportLabel: { [key in PassportType]: string } = {
    ID_CARD: 'ID-картка',
    OLD_PASSPORT: 'Паспорт старого зразка'
};
export enum CalendarView {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    AGENDA = 'agenda'
}

export const CalendarViewList = [
    { id: CalendarView.DAY, name: 'День' },
    { id: CalendarView.WEEK, name: 'Тиждень' },
    { id: CalendarView.MONTH, name: 'Місяць' }
];

export enum DaysOfWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export const DaysOfWeekLabel: { [key in DaysOfWeek]: string } = {
    [DaysOfWeek.MONDAY]: 'Понеділок',
    [DaysOfWeek.TUESDAY]: 'Вівторок',
    [DaysOfWeek.WEDNESDAY]: 'Середа',
    [DaysOfWeek.THURSDAY]: 'Четвер',
    [DaysOfWeek.FRIDAY]: "П'ятниця",
    [DaysOfWeek.SATURDAY]: 'Субота',
    [DaysOfWeek.SUNDAY]: 'Неділя'
};

export const SIZE_CONFIG = {
    'extra-small': { circle: '12px', fontSize: '5px' },
    small: { circle: '28px', fontSize: '12px' },
    medium: { circle: '42px', fontSize: '20px' },
    large: { circle: '64px', fontSize: '28px' },
    'extra-large': { circle: '110px', fontSize: '40px' }
};

export const listOfClass = {
    [EducationLevelGroup.HIGH_SCHOOL]: [
        { id: 'class_5', name: '5 клас' },
        { id: 'class_6', name: '6 клас' },
        { id: 'class_7', name: '7 клас' },
        { id: 'class_8', name: '8 клас' },
        { id: 'class_9', name: '9 клас' },
        { id: 'class_10', name: '10 клас' },
        { id: 'class_11', name: '11 клас' }
    ],
    [EducationLevelGroup.JUNIOR_SCHOOL]: [
        { id: 'class_1', name: '1 клас' },
        { id: 'class_2', name: '2 клас' },
        { id: 'class_3', name: '3 клас' },
        { id: 'class_4', name: '4 клас' }
    ],
    [EducationLevelGroup.PRESCHOOL]: [
        { id: 'years_4', name: '4 роки' },
        { id: 'years_5', name: '5 років' },
        { id: 'years_6', name: '6 років' }
    ]
};
